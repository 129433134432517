import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";

function HeroVideo() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>
      <div className="w-full relative content_video flex justify-center md:py-2 py-10">
        <video
          poster="https://firebasestorage.googleapis.com/v0/b/archivos-multimedia.appspot.com/o/Colorado%20Springs%202%20Stock.mp4?alt=media&token=e1100ef1-e74c-402d-b7c4-ed58e71d2020"
          playsInline
          autoPlay
          muted
          loop
          className="w-full md:h-full h-[600px] object-cover"
        >
          <source src="https://firebasestorage.googleapis.com/v0/b/archivos-multimedia.appspot.com/o/Colorado%20Springs%202%20Stock.mp4?alt=media&token=e1100ef1-e74c-402d-b7c4-ed58e71d2020" type="video/mp4" />
        </video>
        <div className="flex md:flex-row flex-col md:w-4/5 mx-auto absolute bottom-[100px] md:bottom-[100px] lg:bottom-[90px] md:text-start text-center text-white">
          <div className="w-full text-center p-4">
            <h1 className="text-[45px] md:text-[3em] lg:text-[4em]">
              {rpdata?.dbSlogan?.[0].slogan}
            </h1>
            <p className="px-5 md:px-[20%]">
              {rpdata?.dbValues?.[0].description}
            </p>
            <ButtonContent btnStyle="three" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroVideo;
